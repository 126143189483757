import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation  } from "react-router-dom";
import { chunk, forEach } from "lodash";
import {
  Section,
  PageBanner,
  BreadCrumb,
  SocialGallery,
  LinkButton,
  EventCard,
  EventContent,
} from "@components";

import { eventDetails, events } from "../data";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const socialMediaImages = [
  {
    imgUrl: "/social/socialbglg.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-5.jpg",
    socialMediaType: "twitter",
  },
  {
    imgUrl: "/social/social-6.jpg",
    socialMediaType: "insta",
  },
  {
    imgUrl: "/social/social-4.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-1.jpg",
    socialMediaType: "twitter",
  },
  {
    imgUrl: "/social/socialbglg.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-5.jpg",
    socialMediaType: "twitter",
  },
  {
    imgUrl: "/social/social-6.jpg",
    socialMediaType: "insta",
  },
  {
    imgUrl: "/social/social-4.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-1.jpg",
    socialMediaType: "twitter",
  },
];

const EventDetails = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    console.log(pathname)

    window.scrollTo(0, 0);
  }, [pathname]);

  let imageChunks = chunk(socialMediaImages, 5);
  let imageChunksForGrid = [];

  forEach(imageChunks, (images, chunkIndex) => {
    let chunks = chunk(images, 2);
    imageChunksForGrid[chunkIndex] = chunks;
  });

  return (
    <React.Fragment>
      {/* pageBanner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Thrissur Pooram`}
      />

      {/* BreadCrumb */}
      <BreadCrumb />
      
      {/* Event Details */}
      <section className="content-area">
        <Container>
          <Row>
            <Col lg={10} md={10} sm={12} xs={12}>
              <EventContent {...eventDetails} />
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <p>Affix</p>
            </Col>
          </Row>
        </Container>
      </section>
      <Section
        id="block-social-post"
        className="section-block"
        title={`What's trending`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={1}
          margin={0}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {imageChunksForGrid.map((socialMediaImages, key) => (
            <SocialGallery socialMediaImages={socialMediaImages} key={key} />
          ))}
        </OwlCarousel>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
      
      {/* Events Section */}
      <Section
        id="block-events"
        className="section-block"
        title="Festivals / Events"
        readMoreLink="#"
        isContainerRelative={true}
        renderedIn={`container`}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={3}
          margin={30}
          loop={false}
          nav={true}
          dots={true}
          autoplay={true}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {events.map((event, key) => (
            <EventCard {...event} key={key} />
          ))}
        </OwlCarousel>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>

      <Section
        id="explore"
        className="explore section-block mob-pt-0"
        title={`Explore Thrissur`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          <Col lg={12} className="mb-2">
            <img
              className="w-100"
              src="/eventDetails/explore-thrissur.jpg"
              alt=""
              title=""
            />
          </Col>
        </Row>
        <div className="text-end mt-3">
          <LinkButton
            isAbsolute={true}
            isCustom={true}
            link={`#`}
            linkText={`explore`}
          />
        </div>
      </Section>
    </React.Fragment>
  );
};

export default EventDetails;
