import React, { useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import { Box } from "@mui/material";

const stickyMenus = [
  {
    name: "Activities",
    scrollTarget: "activities",
  },
  {
    name: "Get Inspired",
    scrollTarget: "get-inspired",
  },
  {
    name: "Choose your stay",
    scrollTarget: "choose-your-stay",
  },
  // {
  //   name: "Tour Packages",
  //   scrollTarget: "tour-packages",
  // },
  {
    name: "Tour Guides",
    scrollTarget: "tour-guides",
  },
  // {
  //   name: "Travel Info",
  //   scrollTarget: "travel-info",
  // },
  {
    name: "Festivals / Events",
    scrollTarget: "festivals",
  },
  // {
  //   name: "Food",
  //   scrollTarget: "food",
  // },
];

const StickyMenuItem = ({ href, name }) => {
  return (
    <Nav.Item>
      <Nav.Link
        as={Link}
        activeClass="active" 
        to={href} 
        spy={true} 
        smooth={true} 
        duration={500}
        className="nav-link text-uppercase ibm-font-16 text-bold"
      >
        {name}
      </Nav.Link>
    </Nav.Item>
  );
};

const StickyMenu = () => {

  const stickyWrapperRef = React.createRef();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }  
  }, [])

  const handleScroll = () => {
    const sticky = stickyWrapperRef.current.offsetTop;
    if (window.pageYOffset > sticky) {
      stickyWrapperRef.current.classList.add('menu_fixed')
    } else {
      stickyWrapperRef.current.classList.remove('menu_fixed');
    }
  }

  return (
    <Box
      id="sticky_menu"
      className="block_nav_pills nav_pills_list border-bottom"
      ref={stickyWrapperRef}
    >
      <Container>
        <Nav
          variant="pills"
          className="web-d-block -trans ms-auto"
        >
          {stickyMenus.map((menu, key) => (
            <StickyMenuItem
              href={menu.scrollTarget}
              name={menu.name}
              key={key}
            />
          ))}
        </Nav>
      </Container>
    </Box>
  );
};

export default StickyMenu;
