import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { fetchWhatsNew } from "@api";
import { Section } from "@components";

import WhatNewItem from './components/WhatNewItem';

import "../css/whatNew.css";

const WhatNew = () => {
  const [whatsNew, setWhatNew] = useState([]);

  useEffect(() => {
    const fetchWhatsNewItems = async () => {
      const response = await fetchWhatsNew();
      const {
        data: { whats_new },
      } = response.data;
      setWhatNew(whats_new);
    };
    fetchWhatsNewItems();
  }, []);

  return (
    <Section
      id="block-essential"
      className="reg-slide-block section-block"
      title={`What's new`}
      description={`Here's a quick glance on what's trending in God's Own Country now.`}
      isFluid={false}
      renderedIn={`container`}
    >
      {whatsNew.length > 0 && (
        <OwlCarousel
          className="owl-theme"
          items={1}
          margin={0}
          loop={false}
          nav={true}
          dots={true}
          autoplay={true}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-prev.svg'/>",
            "<img src='/icons/arrow-next.svg'/>",
          ]}
        >
          {whatsNew.map((whatNew, key) => (
            <WhatNewItem {...whatNew} key={key} />
          ))}
        </OwlCarousel>
      )}
    </Section>
  );
};

export default WhatNew;
