import React from "react";
import { Card } from "react-bootstrap";

const OtherStoryItem = ({ imageUrl, storyTittle, description }) => {
  return (
    <Card className="border-0 bg-transparent">
      <a href="#" className="d-block img-hover">
        <img className="w-100 -trans" src={imageUrl} alt="" title="" />
      </a>
      <Card.Body className="px-0">
        <h3 className="card-title line-1 ibm-font-22 text-bold mb-3">
          <a href="" className="text-black underline-0 hover-theme">
            { storyTittle }
          </a>
        </h3>
        <p className="card-text ibm-font ibm-font-18 text-bold line-3">
          { description }
        </p>
      </Card.Body>
    </Card>
  );
};

export default OtherStoryItem;
