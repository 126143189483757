import React from "react";
import { Col } from "react-bootstrap";
import { EventCard } from "@components";

import "./css/eventListItem.css";

const EventListItem = () => {
  return (
    <Col
      lg={6}
      md={6}
      sm={12}
      xs={12}
      className={`border-0 rounded-0 event-list-item mb-4`}
    >
      <EventCard
        title="Thrissur Pooram"
        venue="Thekkinkadu Maidanam"
        conductingDate="10"
        imgUrl="/assets/images/listing1.jpg"
        conductingMonth="May"
        titleFontClass="text-white ibm-font-22 text-bold"
        url='/events/thrissur-pooram'
      />
    </Col>
  );
};

export default EventListItem;
