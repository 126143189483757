import React from 'react';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

const links = [
  {
    title: "Where to go",
    to: "/where-to-go",
  },
  {
    title: "What to do",
    to: "/",
  },
  {
    title: "Plan your trip",
    to: "/",
  },
];


const IntroOptionLink = ({ index, title, to}) => {

  let linkWrapperClassName = classNames({
    'head-span': true,
    'position-relative' : (index === 0 || index === 1) ? true : false,
    'head-span1': index === 0 ? true : false,
    'head-span2 text-white px-3 mx-2': index === 1 ? true : false,
    'head-span3': index === 2 ? true : false
  });

  return (
    <span className={linkWrapperClassName}>
      <Link
        to={to}
        className="heroText p-0 border-0 bg-transparent text-decoration-none text-white"
      >
        {title}
      </Link>
  </span>
  );
};

const IntroOptions = () => {
  return (
    <Container>
      <div className="mid-sec">
        <h2 className="mb-0 font-flair hero-text">
          {links.map((link, key) => (
            <IntroOptionLink 
              index={key}
              key={key}
              {...link}
            />
          ))}
        </h2>
      </div>
    </Container>
  )
};

export default IntroOptions;