import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";

import { Card, LinkButton } from "@components";

const FavIcon = () => {
  return (
    <span className="icon-fav -trans position-absolute">
      <a
        onClick={(e) => {
          e.preventDefault();
        }}
        className="d-inline-block text-yellow"
        href="#"
      >
        <img 
          src="/discoverKerala/favourite_yellow.svg" 
          alt="" 
          title="" 
        />
      </a>
    </span>
  );
};

const CardBackground = ({ backgroundImg }) => {
  return (
    <span className="icon-map -trans position-absolute">
      <img src={backgroundImg} alt="" title="" />
    </span>
  )
};

const DestinationCard = ({
  heading,
  description,
  district,
  imgUrl,
  imgMap,
}) => {
  return (
    <Card imgUrl={imgUrl}>
      <figcaption className="fig-caption">
        <h2 className="font-flair text-white">{heading}</h2>
        <p className="text-white ibm-font-18">{description}</p>
        <LinkButton buttonColor="white" link="/" linkText="more" />
      </figcaption>
      <FavIcon/>
      <CardBackground backgroundImg={imgMap}/>
      <h5 className="mb-0 btm-txt w-100 -trans position-absolute">
        <Link
          to="/"
          className="d-block link-btn underline-0 ibm-font-18 text-white"
        >
          {district}
        </Link>
      </h5>
    </Card>
  );
};

DestinationCard.propTypes = {
  heading: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  district: propTypes.string.isRequired,
  imgUrl: propTypes.string.isRequired,
};

export default DestinationCard;
