import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  PageBanner,
  BreadCrumb,
  EventContent,
  Section,
  LinkButton,
  EventCard,
} from "@components";

import { Pagination, Box } from "@mui/material";
import { VideoItem } from "@pages/Home/shared";

import { events, keralaSouvenirs } from "./data";

import ContactCard from "./shared/ContactCard";
import FilterSideBar from "./shared/FilterSidebar";
import CardItem from './shared/CardItem';

const souvenirIntro = {
  eventTitle: `Every day is a lazy, laid-back day here if you want it to be, 
    that is. You can begin your day with a hot cuppa in hand`,
  eventContent: `<p>With its crescent-shaped, palm-fringed beaches, golden sands and beautiful vistas, Kovalam is the favourite beach destination of any traveller. Earlier, in the 1970s, Kovalam was known for being part of the hippie trail. The beach has changed over the years but continues to charm travellers with its aura and enrich them with a multitude of experiences.</p>`,
};

const SouvenirList = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* pageBanner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Souvenirs Shops`}
      />

      {/* BreadCrumb */}
      <BreadCrumb />

      <section className="content-area">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <EventContent {...souvenirIntro} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Souvenir contact card and Filter */}
      <section className="content-area mb-3">
        <Container>
          <Row>
            <Col lg={3} md={3} sm={12} xs={12}>
              <FilterSideBar />
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <Row>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                  <Col lg={4} key={item}>
                    <ContactCard
                      contactName={`Prakrithi Jaiva Kalavara`}
                      contactAddress={`Organic Farm \n Danappadi Road, Harippadu \n Alappuzha`}
                      contactPhone={`+95862451254`}
                    />
                  </Col>
                ))}
                <Box className="mt-5">
                  <Col xs={12}>
                    <Pagination
                      count={5}
                      color="primary"
                      className={`site_pagination d-flex`}
                    />
                  </Col>
                </Box>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Kerala Souvenirs */}
      <Section
        id="block-souvenirs"
        className="section-block"
        title={`Kerala Souvenirs`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {keralaSouvenirs.map((suvenirs, key) => (
            <CardItem {...suvenirs} key={key}/>
          ))}
        </Row>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>

      {/*  Promotion Video block */}
      <Section
        id="block-video"
        className="section-block"
        title={`Souvenirs Videos`}
        isContainerRelative={true}
        innerHead={true}
        renderedIn={`container`}
      >
        <VideoItem />
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
      
      {/* Festivals / events */}
      <Section
        id="block-souvenirs"
        className="section-block"
        title={`Festivals / Events`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {events.map((event, key) => (
            <Col lg={4} key={key}>
              <EventCard {...event}/>
            </Col>
          ))}
        </Row>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
    </React.Fragment>
  );
};

export default SouvenirList;
