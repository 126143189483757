export const articleDetails = {
  articleTitle: `Every day is a lazy, laid-back day here if you want it to be, that is.
  You can begin your day with a hot cuppa in hand, walk along languid sandy stretches 
  and laze away to glory.`,
  articleContent: `
    <p>With its crescent-shaped, palm-fringed beaches, golden sands and beautiful vistas, 
    Kovalam is the favourite beach destination of any traveller. 
    Earlier, in the 1970s, Kovalam was known for being part of the hippie trail. 
    The beach has changed over the years but continues to charm travellers with its aura and enrich them with a multitude of experiences.</p>
    <p>Kovalam is a small coastal town in Thiruvananthapuram, the capital of Kerala. With its three crescent-shaped beaches, and the cultural, heritage, wellness, zen, and adventure sports experiences they have on offer, Kovalam is a beach lover's paradise.</p>
    <p>The beaches have turned into a destination that has something in store for everyone. Be the traveller or tourist an ardent beach lover, a culture buff, an adventure junkie, or one searching for the inner self, Kovalam has something to bestow.</p>
    <p>Kovalam has three beaches viz. Hawa Beach or Eve's beach; Grove's Beach (Samudra Beach) which is also called Kovalam Beach, and the Light House Beach. The lighthouse beach is famed for its mighty lighthouse which offers a breathtaking aerial view of Kovalam.</p>
    <p><strong>Experiences:</strong>Kovalam offers sunbathing, swimming, catamaran cruises, and water sports such as boating, scuba diving, parasailing, surfing, and so on. You can alternately indulge in some holistic or wellness treatment such as yoga and Ayurveda. And if you need to experience some piety, 
    then head to the Azhimala Siva Temple which is an ancient place of worship that boasts of a uniquely crafted mega sculpture of Lord Siva. You can also head to the Kerala Arts and Crafts Village to experience the cultural wealth of Kerala. 
    You get to take home that souvenir you have been longing for or interact with artists and artisans at their workplace or craft studios.
    </p>
    <p><strong>Accommodation</strong>There are a string of budget cottages, Ayurvedic health resorts, convention facilities, shopping zones, swimming pools, Yoga and Ayurvedic massage centres. The choice of food available at restaurants and cafeterias range from Continental varieties to South Indian delicacies.</p>
  `,
};

export const socialMediaImages = [
  {
    imgUrl: "/social/socialbglg.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-5.jpg",
    socialMediaType: "twitter",
  },
  {
    imgUrl: "/social/social-6.jpg",
    socialMediaType: "insta",
  },
  {
    imgUrl: "/social/social-4.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-1.jpg",
    socialMediaType: "twitter",
  },
  {
    imgUrl: "/social/socialbglg.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-5.jpg",
    socialMediaType: "twitter",
  },
  {
    imgUrl: "/social/social-6.jpg",
    socialMediaType: "insta",
  },
  {
    imgUrl: "/social/social-4.jpg",
    socialMediaType: "fb",
  },
  {
    imgUrl: "/social/social-1.jpg",
    socialMediaType: "twitter",
  },
];

export const activities = [
  {
    heading: 'Kovalam, Kerala\'s Top Surfing Destination',
    imageUrl: '/whereToGo/listing1.jpg'
  },
  {
    heading: 'Now Fly & Glide in Kovalam',
    imageUrl: '/whereToGo/listing1.jpg'
  },
  {
    heading: 'Now Fly & Glide in Kovalam',
    imageUrl: '/whereToGo/listing1.jpg'
  },
];

export const getInspired = [
  {
    heading: 'Kovalam,Here Life\'s a Trippy Thing',
    imageUrl: '/whereToGo/listing1.jpg'
  },
  {
    heading: 'Kovalam,Here Life\'s a Trippy Thing',
    imageUrl: '/whereToGo/listing1.jpg'
  }
];

export const chooeYourStay = [
  {
    stayType: 'Ayurvedic Resort',
    imageUrl: '/details/homestay.jpg',
    className: 'ayur_badge_bg'
  },
  {
    stayType: 'Homestay',
    imageUrl: '/details/homestay.jpg',
    className: 'homestay_badge_bg'
  },
  {
    stayType: 'Hotel',
    imageUrl: '/details/homestay.jpg',
    className: 'hotel_badge_bg'
  },
  {
    stayType: 'Resort',
    imageUrl: '/details/homestay.jpg',
    className: 'resort_badge_bg'
  },
  {
    stayType: 'Villa',
    imageUrl: '/details/homestay.jpg',
    className: 'villa_badge_bg'
  },
  {
    stayType: 'Grihasthali',
    imageUrl: '/details/homestay.jpg',
    className: 'griha_badge_bg'
  },
];

export const tourGuides = [
  {
    guideName: 'Robert Downey Jr',
    guideAvatar: '/details/avatarBg.png',
    guideDecription: 'If you are looking forward to a luxury wedding, than you have a myriad places to choose from.',
  },
  {
    guideName: 'Robert Downey Jr',
    guideAvatar: '/details/avatarBg.png',
    guideDecription: 'If you are looking forward to a luxury wedding, than you have a myriad places to choose from.',
  },
  {
    guideName: 'Robert Downey Jr',
    guideAvatar: '/details/avatarBg.png',
    guideDecription: 'If you are looking forward to a luxury wedding, than you have a myriad places to choose from.',
  },
];

export const beachAdventure = [
  {
    heading: 'Drive-in beaches in kerala',
    imageUrl: '/whereToGo/listing1.jpg'
  },
  {
    heading: 'Kovalam',
    imageUrl: '/whereToGo/listing1.jpg'
  },
  {
    heading: 'Alappuzha',
    imageUrl: '/whereToGo/listing1.jpg'
  },
];

export const events = [
  {
    title: `Thrissur Pooram`,
    venue: `Thekkinkadu Maidanam`,
    conductingDate: "1",
    imgUrl: "/discoverKerala/discover1.jpg",
    conductingMonth: "May",
    district: "Thrissur",
  },
  {
    title: `Nehru Trophy Boat Race`,
    venue: `Punnamada Lake`,
    conductingDate: "14",
    imgUrl: "/discoverKerala/discover2.jpg",
    conductingMonth: "August",
    district: "Alappuzha",
  },
  {
    title: `Nishagandhi Dance & Music Festival`,
    venue: `Nishagandhi Auditorium`,
    conductingDate: "29",
    imgUrl: "/discoverKerala/discover3.jpg",
    conductingMonth: "December",
    district: "TVM",
  }
];

export const places = [
  {
    title: "Valiyathura Beach and Pier",
    description:
      "Away from the hustle bustle of the city is situated this beautiful beach, Valiyathura, which is one of the major fishing ports in Thiruvananthapuram.",
    mobileImgUrl: "places/places-mobile.jpg",
    imgUrl: "/places/places-1.jpg",
  },
  {
    title: "Parambikulam",
    description:
      "Panoramic landscape, meandering streams, cascading waterfalls, unique trekking trails and a diverse flora and fauna — the forests of Parambikulam won't let you down.",
    mobileImgUrl: "places/places-mobile.jpg",
    imgUrl: "/places/places-2.jpg",
  },
];

export const activityGallery = [
  '/details/activities-gallery-3.jpg',
  '/details/activities-gallery-2.jpg',
  '/details/activities-gallery-4.jpg'
]
