import React from "react";

export const FacebookIcon = () => {
  return (
    <svg
      width="19px"
      height="35px"
      viewBox="0 0 19 35"
      version="1.1"
    >
      <title>Path</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Desktop-HD"
          transform="translate(-969.000000, -12681.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group-49" transform="translate(970.000000, 12681.000000)">
            <g id="Group-63" transform="translate(0.000000, -0.000000)">
              <path
                d="M17.3796667,11.3333333 L11.3333333,11.3333333 L11.3333333,8.64166667 C11.2789395,8.23613545 11.4077079,7.82741787 11.6847343,7.52630223 C11.9617607,7.22518658 12.3583495,7.06286369 12.767,7.08333333 C13.3591667,7.08333333 17,7.0975 17,7.0975 L17,1.41666667 L10.86725,1.41666667 C5.304,1.41666667 4.25,5.62983333 4.25,8.29458333 L4.25,11.3333333 L2.01320442e-14,11.3333333 L2.01320442e-14,17 L4.25,17 L4.25,34 L11.3333333,34 L11.3333333,17 L16.7889167,17 L17.3796667,11.3333333 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TwitterIcon = () => {
  return (
    <svg
      width="33px"
      height="28px"
      viewBox="0 0 33 28"
      version="1.1"
    >
      <title>Path</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Desktop-HD"
          transform="translate(-1027.000000, -12684.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group-49" transform="translate(970.000000, 12681.000000)">
            <g id="Group-63" transform="translate(0.000000, -0.000000)">
              <path
                d="M89.25,8.50608918 L86.4166667,7.79775584 L87.8333333,4.96442251 L84.3426667,5.95608918 C82.4903815,4.22805176 79.7879001,3.76698326 77.4674348,4.78311057 C75.1469695,5.79923788 73.6529828,8.09793174 73.6666667,10.6310892 L73.6666667,12.0477558 C68.6530833,13.0819225 64.2685,10.3477558 60.2083333,5.67275584 C59.5,9.45053362 60.2083333,12.283867 62.3333333,14.1727558 L58.0833333,13.4644225 C58.6570833,16.3955058 60.0128333,18.7060892 63.75,19.1310892 L60.2083333,20.5477558 C61.625,23.3810892 63.8435,23.8202558 67.2916667,24.0894225 C64.6098029,26.0056559 61.3785411,26.9998903 58.0833333,26.9227558 C76.1529167,34.9538392 86.4166667,23.1487558 86.4166667,12.7560892 L86.4166667,10.8747558 L89.25,8.50608918 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <svg
      width="35px"
      height="34px"
      viewBox="0 0 35 34"
      version="1.1"
    >
      <title>Group 44</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Desktop-HD"
          transform="translate(-1100.000000, -12681.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group-49" transform="translate(970.000000, 12681.000000)">
            <g id="Group-63" transform="translate(0.000000, -0.000000)">
              <g id="Group-44" transform="translate(131.041667, 0.708333)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="32.5833333"
                  height="32.5833333"
                  rx="6"
                ></rect>
                <circle id="Oval" cx="17" cy="17" r="8.5"></circle>
                <circle
                  id="Oval"
                  cx="26.2083333"
                  cy="16.2916667"
                  r="2.125"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const YoutubeIcon = () => {
  return (
    <svg
      width="33px"
      height="34px"
      viewBox="0 0 33 34"
      version="1.1"
    >
      <title>Group 40</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Desktop-HD"
          transform="translate(-1173.000000, -12681.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group-49" transform="translate(970.000000, 12681.000000)">
            <g id="Group-63" transform="translate(0.000000, -0.000000)">
              <g id="Group-40" transform="translate(204.000000, 1.416667)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="16.2916667"
                  width="31.1666667"
                  height="15.5833333"
                  rx="4"
                ></rect>
                <polyline
                  id="Path"
                  points="4.25 2.88139882e-13 8.5 5.66666667 8.50141667 12.0445"
                ></polyline>
                <polyline
                  id="Path"
                  points="12.75 2.88139882e-13 8.5 5.66666667 8.50141667 12.0445"
                ></polyline>
                <path
                  d="M17,9.91666667 C17,11.0902717 16.0486051,12.0416667 14.875,12.0416667 C13.7013949,12.0416667 12.75,11.0902717 12.75,9.91666667 L12.75,5.66666667 C12.75,4.49306157 13.7013949,3.54166667 14.875,3.54166667 C16.0486051,3.54166667 17,4.49306157 17,5.66666667 L17,9.91666667 Z"
                  id="Path"
                ></path>
                <path
                  d="M25.5,3.54166667 L25.5,9.20833333 C25.5,10.7731401 24.2314735,12.0416667 22.6666667,12.0416667 C21.1018599,12.0416667 19.8333333,10.7731401 19.8333333,9.20833333 L19.8333333,3.54166667"
                  id="Path"
                ></path>
                <line
                  x1="25.5"
                  y1="3.53741667"
                  x2="25.5"
                  y2="12.0416667"
                  id="Path"
                ></line>
                <line
                  x1="14.1666667"
                  y1="21.9540833"
                  x2="14.1666667"
                  y2="27.625"
                  id="Path"
                ></line>
                <line
                  x1="4.22025"
                  y1="20.5416667"
                  x2="9.94641667"
                  y2="20.5416667"
                  id="Path"
                ></line>
                <line
                  x1="7.08333333"
                  y1="20.5416667"
                  x2="7.08333333"
                  y2="27.5938333"
                  id="Path"
                ></line>
                <line
                  x1="17"
                  y1="19.125"
                  x2="17"
                  y2="27.5938333"
                  id="Path"
                ></line>
                <path
                  d="M19.8333333,26.2083333 C19.8333333,26.9907367 19.1990701,27.625 18.4166667,27.625 C17.6342633,27.625 17,26.9907367 17,26.2083333 L17,23.375 C17,22.5925966 17.6342633,21.9583333 18.4166667,21.9583333 C19.1990701,21.9583333 19.8333333,22.5925966 19.8333333,23.375 L19.8333333,26.2083333 Z"
                  id="Path"
                ></path>
                <path
                  d="M11.3333333,21.9583333 L11.3333333,26.2083333 C11.3333333,26.9907367 11.9675966,27.625 12.75,27.625 C13.5324034,27.625 14.1666667,26.9907367 14.1666667,26.2083333 L14.1666667,21.9583333"
                  id="Path"
                ></path>
                <path
                  d="M25.7436667,26.3854167 C25.7070212,26.7550863 25.523461,27.0945558 25.2341899,27.3276241 C24.9449189,27.5606923 24.5741704,27.6678344 24.2051667,27.625 C23.3662879,27.5525048 22.7095319,26.8721636 22.6666667,26.03125 L22.6666667,23.375 C22.6666667,22.5925966 23.3009299,21.9583333 24.0833333,21.9583333 C24.8657367,21.9583333 25.5,22.5925966 25.5,23.375 L25.5,24.7916667 L22.6666667,24.7916667"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PinterestIcon = () => {
  return (
    <svg
      width="27px"
      height="35px"
      viewBox="0 0 27 35"
      version="1.1"
    >
      <title>Path</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Desktop-HD"
          transform="translate(-1245.000000, -12680.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group-49" transform="translate(970.000000, 12681.000000)">
            <g id="Group-63" transform="translate(0.000000, -0.000000)">
              <path
                d="M289.619083,-3.19596201e-13 C280.726667,-3.19596201e-13 276.25,6.375 276.25,11.6903333 C276.25,14.9104167 277.46975,17.782 280.082083,18.84025 C280.265583,18.9375066 280.484752,18.9401723 280.670563,18.8474074 C280.856374,18.7546426 280.985957,18.5778645 281.0185,18.37275 C281.106333,18.0455 281.31175,17.21675 281.402417,16.8696667 C281.551158,16.5006976 281.442003,16.0778657 281.13325,15.827 C280.280709,14.7994565 279.840436,13.4917626 279.897917,12.1578333 C279.86851,9.72864628 280.837397,7.39384983 282.578093,5.6992145 C284.31879,4.00457916 286.678725,3.09864064 289.10625,3.19316667 C294.132583,3.19316667 296.897917,6.2645 296.897917,10.36575 C296.897917,15.7604167 294.508,20.3164167 290.962083,20.3164167 C290.045111,20.3620736 289.160773,19.9703809 288.578389,19.2606264 C287.996004,18.5508719 287.784517,17.6070761 288.008333,16.7166667 C288.575,14.34375 289.661583,11.7880833 289.661583,10.07675 C289.749199,9.35799821 289.521839,8.63645942 289.038001,8.09777501 C288.554162,7.5590906 287.861083,7.25585392 287.137083,7.26608333 C285.135333,7.26608333 283.527417,9.33583333 283.527417,12.1110833 C283.514653,13.1292388 283.718433,14.1384803 284.12525,15.0719167 L281.716917,25.2719167 C281.293186,27.6219489 281.274021,30.0270973 281.66025,32.3835833 C281.676277,32.4820301 281.749433,32.5614524 281.846234,32.5854984 C281.943035,32.6095445 282.044852,32.5735869 282.105083,32.4940833 C283.581943,30.6656749 284.725624,28.5917046 285.483833,26.367 C285.713333,25.5325833 286.799917,21.2216667 286.799917,21.2216667 C287.828733,22.7250531 289.550468,23.603261 291.3715,23.5535 C297.38525,23.5464167 301.466667,18.0625 301.466667,10.72275 C301.466667,5.17225 296.766167,-3.19596201e-13 289.619083,-3.19596201e-13 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
