import React from 'react';
import { Col } from "react-bootstrap";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const categories = [
  'All',
  'Theyyam',
  'Boatrace',
  'Padayani',
  'Traditional Festival',
  'Feast',
  'Tourism Events',
  'Music And Dance',
  'Pooram'
];

const FilterSideBar = () => {
  return (
    <React.Fragment>
      <h5 className="mb-3 text-black font-flair">
        Festival Categories
      </h5>
      <Col xs={12}>
        <FormGroup>
          {categories.map((category, key) => (
            <FormControlLabel 
              control={<Checkbox />} 
              label={category} 
              key={key}
            />
          ))}
        </FormGroup>
      </Col>
    </React.Fragment>
  );
};

export default FilterSideBar;