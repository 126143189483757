import React from "react";

const Login = () => {
  return (
    <p>Login</p>
  )
};

export default Login;

