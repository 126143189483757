import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import {
  PageBanner,
  BreadCrumb,
  EventContent,
  EventCard,
  Section,
  LinkButton
} from "@components";

import Card from './shared/Card';

import OwlCarousel from "react-owl-carousel";
import { kalariRelatedInfo, relatedArtForms, events } from "./data";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const eventDetails = {
  eventTitle: 'Kalaripayattu - The martial Artform',
  eventContent: `<p>With its crescent-shaped, palm-fringed beaches, golden sands and beautiful vistas, 
  Kovalam is the favourite beach destination of any traveller. 
  Earlier, in the 1970s, Kovalam was known for being part of the hippie trail. 
  The beach has changed over the years but continues to charm travellers with its aura and enrich them with a multitude of experiences.</p>
  <p>Kovalam is a small coastal town in Thiruvananthapuram, the capital of Kerala. With its three crescent-shaped beaches, and the cultural, heritage, wellness, zen, and adventure sports experiences they have on offer, Kovalam is a beach lover's paradise.</p>
  <p>The beaches have turned into a destination that has something in store for everyone. Be the traveller or tourist an ardent beach lover, a culture buff, an adventure junkie, or one searching for the inner self, Kovalam has something to bestow.</p>
  <p>Kovalam has three beaches viz. Hawa Beach or Eve's beach; Grove's Beach (Samudra Beach) which is also called Kovalam Beach, and the Light House Beach. The lighthouse beach is famed for its mighty lighthouse which offers a breathtaking aerial view of Kovalam.</p>
  <p><strong>Experiences:</strong>Kovalam offers sunbathing, swimming, catamaran cruises, and water sports such as boating, scuba diving, parasailing, surfing, and so on. You can alternately indulge in some holistic or wellness treatment such as yoga and Ayurveda. And if you need to experience some piety, 
  then head to the Azhimala Siva Temple which is an ancient place of worship that boasts of a uniquely crafted mega sculpture of Lord Siva. You can also head to the Kerala Arts and Crafts Village to experience the cultural wealth of Kerala. 
  You get to take home that souvenir you have been longing for or interact with artists and artisans at their workplace or craft studios.
  </p>
  `
};

const ArtFormDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <React.Fragment>
      {/* pageBanner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Kalaripayattu`}
      />
      {/* BreadCrumb */}
      <BreadCrumb />

      {/* Art form Details */}
      <section className="content-area">
        <Container>
          <Row>
            <Col lg={10} md={10} sm={12} xs={12}>
              <EventContent {...eventDetails} />
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <p>Affix</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ArtForm Related Slider */}
      <Section
        id="block-artform-related"
        className="section-block"
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={2}
          margin={15}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {kalariRelatedInfo.map((kalariRelated, key) => (
            <Card {...kalariRelated} key={key} />
          ))}
        </OwlCarousel>
      </Section>

      {/* Explore section */}
      <Section
        id="explore"
        className="explore section-block mob-pt-0"
        title={`Explore Kalaripayattu`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          <Col lg={12} className="mb-2">
            <img
              className="w-100"
              src="/eventDetails/explore-thrissur.jpg"
              alt=""
              title=""
            />
          </Col>
        </Row>
        <div className="text-end mt-3">
          <LinkButton
            isAbsolute={true}
            isCustom={true}
            link={`#`}
            linkText={`explore`}
          />
        </div>
      </Section>

      {/* Related Artforms */}
      <Section
        id="explore"
        className="explore section-block mob-pt-0"
        title={`Related Artforms`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {relatedArtForms.map((relatedArtform, key) => (
            <Col lg={6} key={key}>
              <Card {...relatedArtform} />
            </Col>
          ))}
        </Row>
      </Section>

      {/* Festivals / events */}
      <Section
        id="block-events"
        className="section-block"
        title={`Festivals / Events`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {events.map((event, key) => (
            <Col lg={4} key={key}>
              <EventCard {...event}/>
            </Col>
          ))}
        </Row>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
    </React.Fragment>
  )
};

export default ArtFormDetails;
