import React from 'react';
import { Card, CardContent } from "@mui/material";

const ContactCard = ({
  contactName,
  contactAddress,
  contactPhone
}) => {
  return (
    <Card variant="outlined" className="mb-4">
      <CardContent>
        <h5>{contactName}</h5>
        <address>
          { contactAddress }
        </address>
        <p className="mb-0">
          <i className="la la-phone"></i>
          { contactPhone }
        </p>
      </CardContent>
    </Card>
  );
};

export default ContactCard;