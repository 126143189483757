import "./App.css";
import { Routes, Route } from "react-router-dom";

import Layout from "@layout";

import Home from "@pages/Home";
import WhereToGo from "@pages/whereToGo";
import Destination from "@pages/destination/destination"
import DestinationDetails from "@pages/destination/details";
import EventList from "@pages/event/list";
import EventDetails from "@pages/event/detail";
import SouvenirList from "@pages/souvenir/list";
import SouvenirDetails from "@pages/souvenir/details";
import ArtFormList from "@pages/artform/list";
import ArtFormDetails from "@pages/artform/details";

import Registration from "@pages/Registration";
import Login from "@pages/Login";
import TestComponent from './TestComponent';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home/>}/>
        <Route path="where-to-go" element={<WhereToGo />} />
        <Route path="destination">
          <Route index element={<Destination/>}/>
          <Route path=":slug" element={<DestinationDetails/>}/>
        </Route>
        <Route path='events'>
          <Route index element={<EventList/>}/>
          <Route path=":slug" element={<EventDetails/>}/>
        </Route>
        <Route path="souvenir-shops">
          <Route index element={<SouvenirList/>}/>
          <Route path=":slug" element={<SouvenirDetails/>}/>
        </Route>
        <Route path="art-forms">
          <Route index element={<ArtFormList/>}/>
          <Route path=":slug" element={<ArtFormDetails/>}/>
        </Route>
        <Route path="test">
          <Route index element={<TestComponent/>}/>
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Route>
      <Route path="/registration" element={<Registration/>}/>
      <Route path="/login" element={<Login/>}/>
    </Routes>
  );
};

export default App;
