import React from "react";
import { startCase, toLower } from "lodash";
import { Box } from "@mui/system";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";

const BreadCrumb = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((item) => item !== "");

  return (
    <Box className="btm-menu py-3 bg-light border-bottom mb-5 mob-mb-2">
      <Container>
        <Row>
          <Col lg={8} className="mob-pb-3">
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/` }}>
                Home
              </Breadcrumb.Item>
              {paths.map((url, key) => {
                if (key == 0) {
                  return (
                    <Breadcrumb.Item
                      linkAs={Link}
                      linkProps={{ to: `/${url}` }}
                      key={key}
                    >
                      {startCase(toLower(url))}
                    </Breadcrumb.Item>
                  );
                } else {
                  return (
                    <Breadcrumb.Item
                      active
                      className="text-bold text-black"
                      key={key}
                    >
                      {startCase(toLower(url))}
                    </Breadcrumb.Item>
                  );
                }
              })}
            </Breadcrumb>
          </Col>
          <Col lg={4} className="text-end">
            <a
              href="#"
              className="hover-theme underline-0 text-dark pr-3 text-bold"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Photo Gallery
            </a>
            <a
              href="#"
              className="hover-theme underline-0 text-dark border-left pl-3 text-bold"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Video Gallery
            </a>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

export default BreadCrumb;
