import React, { useState } from "react";
import { Tab, Nav, Row, Col } from "react-bootstrap";

import Form from "./Form";
import Promotion from "./Promotion";
import Weather from "./Weather";

const PlanYourTrip = () => {
  const [key, setKey] = useState("where-to-stay");

  return (
    <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
      <Nav variant="pills" as="ul" className="mb-5 border-bottom">
        <Nav.Item as="li">
          <Nav.Link
            eventKey="where-to-stay"
            as="button"
            className="text-uppercase font-22 text-black text-bold"
          >
            Where To Stay
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="tour-operator"
            as="button"
            className="text-uppercase font-22 text-black text-bold"
          >
            Find a tour operator
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="plan-your-itinerary"
            as="button"
            className="text-uppercase font-22 text-black text-bold"
          >
            Plan your itinerary
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Row>
        <Col xs={8}>
          <Tab.Content>
            <Tab.Pane eventKey="where-to-stay">
              <Row>
                <Form />
                <Promotion
                  heading={`Ten off-beat wedding locations in Kerala`}
                  description={`If you are looking forward to a luxury wedding, than you have a myriad places to choose from.`}
                  promotionImage={`/planYourTrip/tabbg1.jpg`}
                />
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="tour-operator">
              <Row>
                <Form />
                <Promotion
                  heading={`Ten off-beat wedding locations in Kerala`}
                  description={`If you are looking forward to a luxury wedding, than you have a myriad places to choose from.`}
                  promotionImage={`/planYourTrip/tabbg2.jpg`}
                />
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="plan-your-itinerary">
              <Row>
                <Form />
                <Promotion
                  heading={`Ten off-beat wedding locations in Kerala`}
                  description={`If you are looking forward to a luxury wedding, than you have a myriad places to choose from.`}
                  promotionImage={`/planYourTrip/tabbg3.jpg`}
                />
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
        <Col xs={4}>
          <Weather />
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default PlanYourTrip;
