import React from "react";
import { Col } from "react-bootstrap";
import parse from "html-react-parser";

const ArticleContent = ({ articleTitle, articleContent }) => {
  return (
    <Col xs={12}>
      <div className="list_section bg-white pb-0 pt-0 mob-pt-0">
        <div className="head-desc pb-0 mb-5 mob-mb-2">
          <h3 className="head-title text-black font-flair font-flair-regular">
            {articleTitle}
          </h3>
        </div>
        <div className="page-content mb-5 mob-mb-3">
          {parse(articleContent)}
        </div>
      </div>
    </Col>
  );
};

export default ArticleContent;
