import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Box } from '@mui/material';

const CardItem = ({ heading, imageUrl }) => {
  return (
    <Col lg={6} className="border-0 rounded-0 w-100">
      <Box mb={2} className={`position-relative fig-overlay rounded`}>
      <Link
        to={`/destination/${heading.toLowerCase()}`}
        className="img_link d-block mb-0 p-0 img-zoom rounded"
      >
        <img
          src={imageUrl}
          className="card-img-top w-100 rounded -trans"
          alt="..."
          title=""
        />
      </Link>

      <h5 className="gallery-card-title mb-0 p-3 ibm-font-22 text-bold position-absolute">
        <Link
          to={`/destination/${heading.toLowerCase()}`}
          className="text-white text-decoration-none p-0 font-flair font-flair-22 hover-theme"
        >
          {heading}
        </Link>
      </h5>
      </Box>
    </Col>
  );
};

export default CardItem;
