import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PageBanner, BreadCrumb, Section, LinkButton, EventCard, Essentials } from "@components";

import FilterSideBar from "./Shared/FilterSidebar";
import SearchResults from './Shared/SearchResults';
import OtherStoryItem from './Shared/OtherStoryItem';
import WhyKeralaItem from './Shared/WhyKeralaItem';

import { otherStories, events } from './data';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Destination = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* Page Banner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Destination`}
      />

      {/* BreadCrumb */}
      <BreadCrumb />

      {/* Search Area */}
      <section className="content-area">
        <Container>
          <Row>
            <FilterSideBar />
            <SearchResults/>
          </Row>
        </Container>
      </section>

      {/* Other Stories */}
      <Section
        id="block-stories"
        className="more-stories bg-light section-block"
        title={`Other Stories`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row className="mb-4">
          {otherStories.map((story, key) => (
            <Col lg={4} key={key}>
              <OtherStoryItem key={key} {...story}/>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <LinkButton
            isAbsolute={false}
            isCustom={true}
            link={`#`}
            linkText={`more`}
          />
        </div>
      </Section>

      {/* Why Kerala Section */}
      <WhyKeralaItem/>

      {/* Events Section */}
      <Section
        id="block-events"
        className="section-block mob-pt-0 pt-0"
        title="Key Events"
        readMoreLink="#"
        isContainerRelative={true}
        renderedIn={`container`}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={3}
          margin={30}
          loop={false}
          nav={true}
          dots={true}
          autoplay={true}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {events.map((event, key) => (
            <EventCard {...event} key={key} />
          ))}
        </OwlCarousel>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
      
      {/* Essentials Section */}
      <Essentials/>

    </React.Fragment>
  );
};

export default Destination;
