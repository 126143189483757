import React from "react";
import LogoImage from "../assets/logo.png";

const Logo = () => {
  return (
    <h1 className="p-0 mb-0">
      <img className="img-fluid" src={LogoImage} alt="" />
    </h1>
  );
};

export default Logo;
