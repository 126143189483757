export const searchResults = [
  {
    imageUrl: '/destination/listing1.jpg',
    heading: 'Kovalam',
  },
  {
    imageUrl: '/destination/listing2.jpg',
    heading: 'Varkkala',
  },
  {
    imageUrl: '/destination/listing1.jpg',
    heading: 'Cherai',
  },
  {
    imageUrl: '/destination/listing2.jpg',
    heading: 'Bekkal',
  },
  {
    imageUrl: '/destination/listing1.jpg',
    heading: 'Alappuzha',
  },
  {
    imageUrl: '/destination/listing2.jpg',
    heading: 'Muzhappilangadu',
  },
];

export const otherStories = [
  {
    imageUrl: '/destination/moreStories1.jpg',
    storyTittle: 'Edakkal Caves, Wayanad - A Journey to the',
    description: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'
  },
  {
    imageUrl: '/destination/moreStories1.jpg',
    storyTittle: 'Edakkal Caves, Wayanad - A Journey to the',
    description: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'
  },
  {
    imageUrl: '/destination/moreStories1.jpg',
    storyTittle: 'Edakkal Caves, Wayanad - A Journey to the',
    description: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'
  },
  {
    imageUrl: '/destination/moreStories1.jpg',
    storyTittle: 'Edakkal Caves, Wayanad - A Journey to the',
    description: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'
  },
  {
    imageUrl: '/destination/moreStories1.jpg',
    storyTittle: 'Edakkal Caves, Wayanad - A Journey to the',
    description: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'
  },
  {
    imageUrl: '/destination/moreStories1.jpg',
    storyTittle: 'Edakkal Caves, Wayanad - A Journey to the',
    description: 'Some quick example text to build on the card title and make up the bulk of the card\'s content.'
  }
];

export const events = [
  {
    title: `Thrissur Pooram`,
    venue: `Thekkinkadu Maidanam`,
    conductingDate: "1",
    imgUrl: "/discoverKerala/discover1.jpg",
    conductingMonth: "May",
    district: "Thrissur",
  },
  {
    title: `Nehru Trophy Boat Race`,
    venue: `Punnamada Lake`,
    conductingDate: "14",
    imgUrl: "/discoverKerala/discover2.jpg",
    conductingMonth: "August",
    district: "Alappuzha",
  },
  {
    title: `Nishagandhi Dance & Music Festival`,
    venue: `Nishagandhi Auditorium`,
    conductingDate: "29",
    imgUrl: "/discoverKerala/discover3.jpg",
    conductingMonth: "December",
    district: "TVM",
  },
  {
    title: `Thrissur Pooram`,
    venue: `Thekkinkadu Maidanam`,
    conductingDate: "1",
    imgUrl: "/discoverKerala/discover4.jpg",
    conductingMonth: "May",
    district: "Thrissur",
  },
];