import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  PageBanner,
  BreadCrumb,
  Section,
  EventCard,
  Essentials,
  LinkButton
} from "@components";
import { Pagination, Box } from "@mui/material";

import FilterSidebar from "./shared/FilterSidebar";
import SearchResult from "./shared/SearchResult";

import { events } from './shared/data';

const ArtFormList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* pageBanner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Artforms`}
      />
      {/* BreadCrumb */}
      <BreadCrumb />

      {/* Search Filter Area */}
      <section className="content-area">
        <Container>
          <Row>
            <FilterSidebar />
            <SearchResult />
            <Box className="mt-5">
              <Col xs={12}>
                <Pagination
                  count={5}
                  color="primary"
                  className={`site_pagination d-flex`}
                />
              </Col>
            </Box>
          </Row>
        </Container>
      </section>

      {/* Festivals / events */}
      <Section
        id="block-events"
        className="section-block"
        title={`Festivals / Events`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {events.map((event, key) => (
            <Col lg={4} key={key}>
              <EventCard {...event}/>
            </Col>
          ))}
        </Row>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>

      {/* Essentials Section */}
      <Essentials />
    </React.Fragment>
  );
};

export default ArtFormList;
