import React, { useEffect } from "react";
import { Element } from "react-scroll";
import { Box } from "@mui/system";
import { chunk, forEach } from "lodash";
import {
  PageBanner,
  BreadCrumb,
  StickyMenu,
  ArticleContent,
  SocialGallery,
  Section,
  EventCard,
  LinkButton,
  Place,
} from "@components";

import { Container, Col, Row } from "react-bootstrap";
import {
  articleDetails,
  socialMediaImages,
  activities,
  getInspired,
  chooeYourStay,
  tourGuides,
  events,
  places,
  activityGallery,
  beachAdventure,
} from "./data";

import { CardItem, StayList, GuideProfile } from "./Shared";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const DestinationDetails = () => {
  let imageChunks = chunk(socialMediaImages, 5);
  let imageChunksForGrid = [];

  forEach(imageChunks, (images, chunkIndex) => {
    let chunks = chunk(images, 2);
    imageChunksForGrid[chunkIndex] = chunks;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* Page  Banner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Kovalam Beach`}
      />

      {/* Bread Crumb */}
      <BreadCrumb />

      {/* Sticky Menu */}
      <StickyMenu />

      {/* Article Details */}
      <Container>
        <Box className="mt-5 pt-5">
          <Row>
            <Col lg={10}>
              <ArticleContent {...articleDetails} />
            </Col>
            {/* Todo :- Affix Sidebar */}
            <Col lg={2}></Col>
          </Row>
        </Box>
      </Container>

      {/* Social Media Gallery */}
      <Section
        id="block-social-post"
        className="section-block"
        title={`What's trending`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={1}
          margin={0}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {imageChunksForGrid.map((socialMediaImages, key) => (
            <SocialGallery socialMediaImages={socialMediaImages} key={key} />
          ))}
        </OwlCarousel>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>

      {/* Activities */}
      <Element name="activities">
        <Section
          id="block-activities"
          className="section-block"
          title={`Activities`}
          readMoreLink={`#`}
          renderedIn={`container`}
          innerHead={true}
          isContainerRelative={true}
        >
          <OwlCarousel
            className="owl-theme carousel-mirror"
            items={2}
            margin={15}
            loop={false}
            nav={true}
            dots={false}
            autoplay={false}
            smartSpeed={1200}
            autoplayTimeout={10000}
            navText={[
              "<img src='/icons/arrow-sm-prev.svg' />",
              "<img src='/icons/arrow-sm-next.svg'/>",
            ]}
          >
            {activities.map((activity, key) => (
              <CardItem {...activity} key={key} />
            ))}
          </OwlCarousel>
        </Section>
      </Element>

      {/* Get Inspired */}
      <Element name="get-inspired">
        <Section
          id="block-inspired"
          className="section-block"
          title={`Get Inspired`}
          readMoreLink={`#`}
          renderedIn={`container`}
          innerHead={true}
          isContainerRelative={true}
        >
          <OwlCarousel
            className="owl-theme carousel-mirror"
            items={2}
            margin={15}
            loop={false}
            nav={true}
            dots={false}
            autoplay={false}
            smartSpeed={1200}
            autoplayTimeout={10000}
            navText={[
              "<img src='/icons/arrow-sm-prev.svg' />",
              "<img src='/icons/arrow-sm-next.svg'/>",
            ]}
          >
            {getInspired.map((inspiration, key) => (
              <CardItem {...inspiration} key={key} />
            ))}
          </OwlCarousel>
        </Section>
      </Element>

      {/* Choose Your Stay */}
      <Element name="choose-your-stay">
        <Section
          id="block-stay"
          className="section-block"
          title={`Choose your stay`}
          readMoreLink={`#`}
          renderedIn={`container`}
          innerHead={true}
          isContainerRelative={true}
        >
          <StayList stayLists={chooeYourStay} />
        </Section>
      </Element>

      {/* Tour Guides */}
      <Element name="tour-guides">
        <Section
          id="block-stay"
          className="section-block pt-0"
          title={`Tour Guides`}
          readMoreLink={`#`}
          renderedIn={`container`}
          innerHead={true}
          isContainerRelative={true}
        >
          <Row>
            {tourGuides.map((tourGuide, key) => (
              <GuideProfile {...tourGuide} key={key} />
            ))}
          </Row>
        </Section>
      </Element>

      {/* Festivals / Events */}
      <Element name="festivals">
        <Section
          id="block-events"
          className="section-block pt-0"
          title={`Festivals/Events`}
          readMoreLink={`#`}
          renderedIn={`container`}
          innerHead={true}
          isContainerRelative={true}
        >
          <Row>
            {events.map((event, key) => (
              <Col lg={4} key={key}>
                <EventCard {...event} />
              </Col>
            ))}
          </Row>
          <LinkButton
            isAbsolute={true}
            isCustom={true}
            link={`#`}
            linkText={`explore`}
          />
        </Section>
      </Element>

      {/* Places Carousel */}
      <Section
        id="block-reg"
        className="reg-slide-block section-block pt-4 pb-0"
        renderedIn={`container`}
      >
        <OwlCarousel
          className="owl-theme"
          items={1}
          margin={0}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-prev.svg'></i>",
            "<img src='/icons/arrow-next.svg'/>",
          ]}
        >
          {places.map((place, key) => (
            <Place {...place} key={key} />
          ))}
        </OwlCarousel>
      </Section>

      {/* Beach Gallery */}
      <Section
        id="travel-info-gallery"
        className="section-block mob-pt-0 pb-0"
        title={`Sky Above, Sand Below`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          <Col xs={7}>
            <img className="w-100" src={activityGallery[0]} alt="" title="" />
          </Col>
          <Col lg={5}>
            <Row>
              <Col xs={12} className="mb-2">
                <img
                  className="w-100"
                  src={activityGallery[1]}
                  alt=""
                  title=""
                />
              </Col>
              <Col xs={12}>
                <img
                  className="w-100"
                  src={activityGallery[2]}
                  alt=""
                  title=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>

      {/* Beach Adeventure */}

      {/* Get Inspired */}
      <Section
        id="block-adventure"
        className="section-block"
        title={`Beach Adventure`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={2}
          margin={15}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {beachAdventure.map((adventure, key) => (
            <CardItem {...adventure} key={key} />
          ))}
        </OwlCarousel>
      </Section>

      {/* Explore Destination */}

      <Section
        id="explore"
        className="explore section-block mob-pt-0"
        title={`Explore Thiruvananthapuram`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          <Col lg={12} className="mb-2">
            <img
              className="w-100"
              src="/details/explore-tvm.jpg"
              alt=""
              title=""
            />
          </Col>
        </Row>
        <div className="text-end mt-3">
          <LinkButton
            isAbsolute={true}
            isCustom={true}
            link={`#`}
            linkText={`explore`}
          />
        </div>
      </Section>
    </React.Fragment>
  );
};

export default DestinationDetails;
