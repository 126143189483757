import React from 'react';
import { Col } from "react-bootstrap";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const ditricts = [
  'Thiruvananthapuram',
  'Kollam',
  'Pathanamthitta',
  'Alappuzha',
  'Kottayam',
  'Idukki',
  'Ernakulam',
  'Thrissur',
  'Palakkad',
  'Malappuram',
  'Kozhikode',
  'Wayanad',
  'Kannur',
  'Kasaragod'
];

const FilterSideBar = () => {
  return (
    <React.Fragment>
      <h5 className="mb-3 text-black font-flair">
        Districts
      </h5>
      <Col xs={12}>
        <FormGroup>
          {ditricts.map((category, key) => (
            <FormControlLabel 
              control={<Checkbox />} 
              label={category} 
              key={key}
            />
          ))}
        </FormGroup>
      </Col>
    </React.Fragment>
  );
};

export default FilterSideBar;