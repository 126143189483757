import React from 'react';
import { Col, Accordion } from "react-bootstrap";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import './filterSidebar.css';

const ditricts = [
  'Thiruvananthapuram',
  'Kollam',
  'Pathanamthitta',
  'Alappuzha',
  'Kottayam',
  'Idukki',
  'Ernakulam',
  'Thrissur',
  'Palakkad',
  'Malappuram',
  'Kozhikode',
  'Wayanad',
  'Kannur',
  'Kasaragod'
];

const regions = [
  'Malabar',
  'Central-Kerala',
  'Travancore'
];

const activity = [
  'Beach-Adventure',
  'Kattamaran',
  'Scuba-Diving'
];

const FilterSideBar = () => {
  return (
    <Col lg={3}>
      <Accordion id={`areaAccordion`}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>District</Accordion.Header>
          <Accordion.Body className={`px-0`}>
            <FormGroup>
              {ditricts.map((district, key) => (
                <FormControlLabel 
                  control={<Checkbox />} 
                  label={district} 
                  key={key}
                />
              ))}
            </FormGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Region</Accordion.Header>
          <Accordion.Body>
            {regions.map((region, key) => (
              <FormControlLabel 
                control={<Checkbox />} 
                label={region} 
                key={key}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Activity</Accordion.Header>
          <Accordion.Body>
            {activity.map((activity, key) => (
              <FormControlLabel 
                control={<Checkbox />} 
                label={activity} 
                key={key}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );
};

export default FilterSideBar;