import React from "react";
import { Row, Col } from "react-bootstrap";
import { searchResults } from './data';
import CardItem from './CardItem';

const SearchResult = () => {
  return (
    <Col lg={9}>
      <Col xs={12} className="destination_list">
        <Row>
          <p className="mb-2 ibm-font-16 text-bold results_found d-flex">
            <span className="text-black">115</span>
            <small href="#" className="text-muted text-decoration-none mr-2">
              Results Found
            </small>{" "}
            <a href="#" className="text-decoration-none text-black ibm-font-16">
              A | Z
            </a>
          </p>
        </Row>
        <Row>
          {searchResults.map((searchResult, key) => (
            <Col xs={6} key={key}>
              <CardItem {...searchResult}/>
            </Col>
          ))}
        </Row>
      </Col>
    </Col>
  );
};

export default SearchResult;
