import React, { useEffect } from "react";
import {
  HomeStorySlider,
  WhatNew,
  Story,
  Memories,
  Discover,
  KeralaStory,
  Events,
  Destination,
  SocialGallery,
  VideoItem,
} from "./shared";

import {
  LinkButton,
  Section,
  Essentials
} from "@components";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* Home Stories Slider */}
      <HomeStorySlider />

      {/* What's new */}
      <WhatNew />

      {/* Essentials */}
      <Essentials />

      {/* Stories */}
      <Story />

      {/* Memories */}
      <Memories />

      {/*  Discover destinations  */}
      <Discover/>

      {/* Kerala Stories */}
      <KeralaStory/>

      {/* Latest Events */}
      <Events/>

      {/* Destination carousal */}
      <Destination/>

      {/* Kerala Tourism Stories section */}
      <SocialGallery/>

      {/*  Promotion Video block */}
      <Section
        id="block-video"
        className="section-block"
        isContainerRelative={true}
        renderedIn={`container`}
      >
        <VideoItem />
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
    </React.Fragment>
  );
};

export default Home;
