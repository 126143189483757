import React from 'react';
import { Col, Accordion } from "react-bootstrap";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import './filterSidebar.css';

const performingArts = [
  'Chavittunatakam',
  'Duffmuttu',
  'Kakkarisi Natakam',
  'Kathakali Classical Artform',
  'Kottayam',
  'Koothu',
  'Krishnannattam',
  'Kutiyattam',
  'Margomkali',
  'Mohiniyattom',
  'Oppana',
  'Thiruvathirakali',
  'Thullal',
];

const ritualArtForms = [];
const martialArts = [];
const folkArts = [];

const FilterSideBar = () => {
  return (
    <Col lg={3}>
      <Accordion id={`areaAccordion`}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Performing Arts</Accordion.Header>
          <Accordion.Body className={`px-0`}>
            <FormGroup>
              {performingArts.map((district, key) => (
                <FormControlLabel 
                  control={<Checkbox />} 
                  label={district} 
                  key={key}
                />
              ))}
            </FormGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Ritual Art Forms</Accordion.Header>
          <Accordion.Body>
            {ritualArtForms.length > 0 && ritualArtForms.map((ritualArt, key) => (
              <FormControlLabel 
                control={<Checkbox />} 
                label={ritualArt} 
                key={key}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Martial Arts</Accordion.Header>
          <Accordion.Body>
            {martialArts.length > 0 && martialArts.map((martialArt, key) => (
              <FormControlLabel 
                control={<Checkbox />} 
                label={martialArt} 
                key={key}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Folk Arts</Accordion.Header>
          <Accordion.Body>
            {folkArts.length > 0 && folkArts.map((folkArt, key) => (
              <FormControlLabel 
                control={<Checkbox />} 
                label={folkArt} 
                key={key}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );
};

export default FilterSideBar;