export const eventDetails = {
  eventTitle: `The Thrissur Pooram is an annual temple festival held in Thrissur, Kerala, India.`,
  eventInfo: {
    date: `10`,
    day: `Thursady`,
    month: `May`,
    year: `2022`,
    venue: `Thekkinkadu Maidanam`,
    place: `Thrissur`
  },
  eventContent: `
    <p>With its crescent-shaped, palm-fringed beaches, golden sands and beautiful vistas, 
    Kovalam is the favourite beach destination of any traveller. 
    Earlier, in the 1970s, Kovalam was known for being part of the hippie trail. 
    The beach has changed over the years but continues to charm travellers with its aura and enrich them with a multitude of experiences.</p>
    <p>Kovalam is a small coastal town in Thiruvananthapuram, the capital of Kerala. With its three crescent-shaped beaches, and the cultural, heritage, wellness, zen, and adventure sports experiences they have on offer, Kovalam is a beach lover's paradise.</p>
    <p>The beaches have turned into a destination that has something in store for everyone. Be the traveller or tourist an ardent beach lover, a culture buff, an adventure junkie, or one searching for the inner self, Kovalam has something to bestow.</p>
    <p>Kovalam has three beaches viz. Hawa Beach or Eve's beach; Grove's Beach (Samudra Beach) which is also called Kovalam Beach, and the Light House Beach. The lighthouse beach is famed for its mighty lighthouse which offers a breathtaking aerial view of Kovalam.</p>
    <p><strong>Experiences:</strong>Kovalam offers sunbathing, swimming, catamaran cruises, and water sports such as boating, scuba diving, parasailing, surfing, and so on. You can alternately indulge in some holistic or wellness treatment such as yoga and Ayurveda. And if you need to experience some piety, 
    then head to the Azhimala Siva Temple which is an ancient place of worship that boasts of a uniquely crafted mega sculpture of Lord Siva. You can also head to the Kerala Arts and Crafts Village to experience the cultural wealth of Kerala. 
    You get to take home that souvenir you have been longing for or interact with artists and artisans at their workplace or craft studios.
    </p>
    <p><strong>Accommodation</strong>There are a string of budget cottages, Ayurvedic health resorts, convention facilities, shopping zones, swimming pools, Yoga and Ayurvedic massage centres. The choice of food available at restaurants and cafeterias range from Continental varieties to South Indian delicacies.</p>
  `,

};

export const events = [
  {
    title: `Thrissur Pooram`,
    venue: `Thekkinkadu Maidanam`,
    conductingDate: "1",
    imgUrl: "/discoverKerala/discover1.jpg",
    conductingMonth: "May",
    district: "Thrissur",
    url: '/events/thrissur-pooram'
  },
  {
    title: `Nehru Trophy Boat Race`,
    venue: `Punnamada Lake`,
    conductingDate: "14",
    imgUrl: "/discoverKerala/discover2.jpg",
    conductingMonth: "August",
    district: "Alappuzha",
    url: '/events/alappuzha'
  },
  {
    title: `Nishagandhi Dance & Music Festival`,
    venue: `Nishagandhi Auditorium`,
    conductingDate: "29",
    imgUrl: "/discoverKerala/discover3.jpg",
    conductingMonth: "December",
    district: "TVM",
    url: '/events/nishagandhi-dance-music-festival'
  },
  {
    title: `Thrissur Pooram`,
    venue: `Thekkinkadu Maidanam`,
    conductingDate: "1",
    imgUrl: "/discoverKerala/discover4.jpg",
    conductingMonth: "May",
    district: "Thrissur",
    url: '/events/thrissur-pooram'
  },
];
