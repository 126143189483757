import React from "react";

const WhyKeralaItem = () => {
  return (
    <div className="skewed-bg">
      <div className="content d-flex">
        <img
          src="/destination/caravan-tourism.jpg"
          alt=""
          title=""
          className="w-100"
        />
        <div className="text_latest_update">
          <div className="container">
            <a
              href="#"
              className="text-decoration-none small-text text-uppercase text-yellow mob-text-white ibm-semi-bold mob-bg-yellow mob-btn"
            >
              Inspiring
            </a>
            <h3 className="head-sub font-flair mb-3">
              <a href="" className="underline-0 text-white">
                Why Kerala, India should be next on your travel bucket list
              </a>
            </h3>
            <p className="text-white ibm-font-18 ibm-light mb-0">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyKeralaItem;
