import React from "react";
import { Col } from "react-bootstrap";

const Card = ({ heading, imageUrl }) => {
  return (
    <Col lg={12} className="border-0 rounded-0">
      <a href="#" className="img_link d-block mb-2">
        <img
          src={imageUrl}
          className="card-img-top w-100 rounded-0"
          alt="..."
          title=""
        />
      </a>
      <h5 className="mb-2">
        <a
          linkTo="/detail"
          className="text-black text-decoration-none p-0 font-flair font-flair-22 hover-theme"
        >
          { heading }
        </a>
      </h5>
    </Col>
  );
};

export default Card;
