import React from "react";
import { Col, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { Section } from "@components";

import '../newsLetter/css/newsLetter.css';

const NewsLetter = () => {
  return (
    <Section
      id="block-newsletter"
      className="section-block-md"
      isWrap={true}
      title={`Subscribe to our newsletter`}
      description={`Sign up for Kerala Tourism's monthly newsletter to learn about our events, offers and more…`}
      renderedIn={`container`}
    >
      <Row className="px-0 newsletterForm">
        <Col xs={5}>
          <TextField label="Name" variant="standard" fullWidth={true} />
        </Col>
        <Col xs={5}>
          <TextField label="Email" variant="standard" fullWidth={true} />
        </Col>
        <Col xs={2} className="text-end">
          <Button
            variant="contained"
            className="text-capitalize mt-2 btn-submit btn btn-md py-2 px-4 font-16 bg-yellow text-dark rounded underline-0 -trans"
          >
            Subscribe
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

export default NewsLetter;
