import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Pagination,
} from "@mui/material";

const InfoItem = ({ info }) => {
  return (
    <Box
      component="p"
      sx={{
        marginBottom: 0,
        fontWeight: "bold",
        fontSize: "1.2em",
      }}
      className="play-fair"
    >
      {info}
    </Box>
  );
};

const SubmittedInfos = ({ handleEdit }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Grid item lg={2}>
        <InfoItem info="Kovalam" />
      </Grid>
      <Grid item lg={2}>
        <InfoItem info="11 Feb 22" />
      </Grid>
      <Grid item lg={2}>
        <InfoItem info="12 Feb 22" />
      </Grid>
      <Grid item lg={2}>
        <InfoItem info="Guests : 4" />
      </Grid>
      <Grid item lg={2}>
        <InfoItem info="Rooms : 4" />
      </Grid>
      <Grid item lg={2}>
        <Button
          variant="contained"
          size="sm"
          onClick={() => {
            handleEdit(true);
          }}
        >
          Modify
        </Button>
      </Grid>
    </Grid>
  );
};

const ModifySubmittedInfos = ({ handleUpdate }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Grid item lg={2}>
        <TextField id="destination" label="Destination" variant="standard" />
      </Grid>
      <Grid item lg={2}>
        <TextField id="check-in" label="Check In" variant="standard" />
      </Grid>
      <Grid item lg={2}>
        <TextField id="check-out" label="Check Out" variant="standard" />
      </Grid>
      <Grid item lg={2}>
        <TextField id="no_of_guests" label="No of guests" variant="standard" />
      </Grid>
      <Grid item lg={2}>
        <TextField id="no_of_rooms" label="No of Rooms" variant="standard" />
      </Grid>
      <Grid item lg={2}>
        <Button
          variant="contained"
          size="sm"
          onClick={() => {
            handleUpdate(false);
          }}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

const InfoLayout = ({ children }) => {
  return (
    <Box
      sx={{
        background: "#e7e7e7",
        p: 2,
        mb: 3.8,
      }}
    >
      {children}
    </Box>
  );
};

const FilterSideBar = () => {
  const propertyTypes = [
    "Ayurvedha Center",
    "HomeStay",
    "Hotel",
    "Resort",
    "Serviced Villa",
    "Tour Operator",
    "Grihasthali Heritage Building",
    "Other Service Providers",
  ];

  const ditricts = [
    "Thiruvananthapuram",
    "Kollam",
    "Pathanamthitta",
    "Alappuzha",
    "Kottayam",
    "Idukki",
    "Ernakulam",
    "Thrissur",
    "Palakkad",
    "Malappuram",
    "Kozhikode",
    "Wayanad",
    "Kannur",
    "Kasaragod",
  ];

  const regions = ["Malabar", "Central-Kerala", "Travancore"];

  return (
    <Accordion id={`areaAccordion`}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Property Type</Accordion.Header>
        <Accordion.Body className={`px-0`}>
          <FormGroup>
            {propertyTypes.map((propertyType, key) => (
              <FormControlLabel
                control={<Checkbox />}
                label={propertyType}
                key={key}
              />
            ))}
          </FormGroup>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>District</Accordion.Header>
        <Accordion.Body>
          {ditricts.map((district, key) => (
            <FormControlLabel
              control={<Checkbox />}
              label={district}
              key={key}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Region</Accordion.Header>
        <Accordion.Body>
          {regions.map((region, key) => (
            <FormControlLabel control={<Checkbox />} label={region} key={key} />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const SearchCardItem = ({ title, imageUrl, address }) => {
  const [isShowHoverCard, setShowHoverCard] = useState(false);

  return (
    <Grid
      item
      lg={4}
      className="border-0 rounded-0 w-100 filter-item"
      onMouseEnter={() => setShowHoverCard(true)}
      onMouseLeave={() => setShowHoverCard(false)}
    >
      {!isShowHoverCard ? (
        <Box className={`position-relative fig-overlay rounded`}>
          <Link to={`/`} className="img_link d-block mb-0 p-0 img-zoom rounded">
            <img
              src={imageUrl}
              className="card-img-top w-100 rounded -trans"
              alt="..."
              title=""
            />
          </Link>
          <h5 className="gallery-card-title mb-0 p-3 ibm-font-22 text-bold position-absolute">
            <Link
              to={`/`}
              className="text-white text-decoration-none p-0 font-flair font-flair-22 hover-theme"
            >
              {title}
            </Link>
          </h5>
        </Box>
      ) : (
        <Box
          sx={{
            border: 0,
            backgroundColor: "#fff",
            p: 3,
          }}
          className="filter-card-item"
        >
          <Link to="/" className="d-block">
            <img
              src={imageUrl}
              className="card-img-top w-100 rounded"
              alt="..."
              title=""
            />
          </Link>
          <Grid
            item
            sx={{
              pl: 0,
              pr: 0,
              pb: 0,
            }}
            className="card-body d-flex flex-column"
          >
            <h5 className="card-title">
              <Link
                to="/"
                className="text-dark text-decoration-none ibm-semi-bold"
              >
                { title }
              </Link>
            </h5>
            <pre className="mb-2 ibm-font-16 font-ibm">
              { address }
            </pre>
            {/* <p className="mb-2">Phone: 478-3253339, 3251452</p> */}
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

const TestComponent = () => {
  const [showInfoForm, setShowInfoForm] = useState(false);

  const searchResults = [
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
    {
      title: "Chakra Ayurvedhic Resort",
      address: `Ezhupunna South Karumancheri Holidays \n
      Pvt.Ltd, Alappuzha,
      Phone: 478-3253339 3251452`,
      imageUrl: "/details/homestay.jpg",
    },
  ];

  return (
    <section
      id="banner"
      className="p-0 position-relative"
      style={{
        marginTop: "120px",
      }}
    >
      <Box
        sx={{
          mb: 8,
        }}
      >
        <Container>
          <Grid item xs={12}>
            <h2 className="mb-3 text-black font-flair head-title text-center">
              Plan Your Kerala Tour
            </h2>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
            <InfoLayout>
              {!showInfoForm ? (
                <SubmittedInfos handleEdit={setShowInfoForm} />
              ) : (
                <ModifySubmittedInfos handleUpdate={setShowInfoForm} />
              )}
            </InfoLayout>
          </Grid>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={3}>
                <FilterSideBar />
              </Grid>
              <Grid item lg={9}>
                <Grid item xs={12}>
                  <p className="mb-2 ibm-font-16 text-bold results_found d-flex">
                    <span className="text-black">115</span>
                    <small
                      href="#"
                      className="text-muted text-decoration-none mr-2"
                    >
                      Results Found
                    </small>{" "}
                    <a
                      href="#"
                      className="text-decoration-none text-black ibm-font-16"
                    >
                      A | Z
                    </a>
                  </p>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{
                    pt: 2,
                  }}
                >
                  <Grid container spacing={2} className="top_filter_item">
                    {searchResults.map((item, key) => (
                      <SearchCardItem {...item} key={key} />
                    ))}
                    <Grid xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <Pagination count={10} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </section>
  );
};

export default TestComponent;
