import React from 'react';
import OwlCarousel from "react-owl-carousel";
import { chunk, forEach } from "lodash";
import { Section, SocialGallery, LinkButton } from "@components";

import { socialMediaImages } from '../data';

// import '../css/socialGallery.css';

const SocialGalleryCarousal = () => {
  let imageChunks = chunk(socialMediaImages, 5);
  let imageChunksForGrid = [];

  forEach(imageChunks, (images, chunkIndex) => {
    let chunks = chunk(images, 2);
    imageChunksForGrid[chunkIndex] = chunks;
  });

  return (
    <Section
      id="block-social-post"
      className="section-block"
      title={`#KeralaTourism`}
      description={`See what's trending about God’s Own Country on social media`}
      isContainerRelative={true}
      readMoreLink={`#`}
      renderedIn={`container`}
    >
      <OwlCarousel
        className="owl-theme carousel-mirror"
        items={1}
        margin={0}
        loop={false}
        nav={true}
        dots={false}
        autoplay={false}
        smartSpeed={1200}
        autoplayTimeout={10000}
        navText={[
          "<img src='icons/arrow-sm-prev.svg' />",
          "<img src='icons/arrow-sm-next.svg'/>",
        ]}
      >
        {imageChunksForGrid.map((socialMediaImages, key) => (
          <SocialGallery socialMediaImages={socialMediaImages} key={key} />
        ))}
      </OwlCarousel>
      {/* <LinkButton
        isAbsolute={true}
        isCustom={true}
        link={`#`}
        linkText={`explore`}
      /> */}
    </Section>
  );
};

export default SocialGalleryCarousal;
