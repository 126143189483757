import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  PageBanner,
  BreadCrumb,
  Essentials,
  Place,
  Section
} from "@components";
import { Pagination, Box } from "@mui/material";

import FilterSideBar from "./shared/filterSideBar";
import EventListItem from "./shared/eventListItem";
import Card from "./shared/card";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const highlights = [
  {
    heading: "Kovalam, Kerala's Top Surfing Destination",
    imageUrl: "/whereToGo/listing1.jpg",
  },
  {
    heading: "Now Fly & Glide in Kovalam",
    imageUrl: "/whereToGo/listing1.jpg",
  },
  {
    heading: "Now Fly & Glide in Kovalam",
    imageUrl: "/whereToGo/listing1.jpg",
  },
];

export const places = [
  {
    title: "Valiyathura Beach and Pier",
    description:
      "Away from the hustle bustle of the city is situated this beautiful beach, Valiyathura, which is one of the major fishing ports in Thiruvananthapuram.",
    mobileImgUrl: "/places/places-mobile.jpg",
    imgUrl: "/places/places-1.jpg",
  },
  {
    title: "Parambikulam",
    description:
      "Panoramic landscape, meandering streams, cascading waterfalls, unique trekking trails and a diverse flora and fauna — the forests of Parambikulam won't let you down.",
    mobileImgUrl: "/places/places-mobile.jpg",
    imgUrl: "/places/places-2.jpg",
  },
];

const EventList = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* pageBanner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Events`}
      />
      {/* BreadCrumb */}
      <BreadCrumb />

      {/* Event Filter and Listing section */}
      <section className="content-area mb-3">
        <Container>
          <Row>
            <Col lg={3} md={3} sm={12} xs={12}>
              <FilterSideBar />
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <Row>
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <EventListItem key={item} />
                ))}
                <Box className="mt-5">
                  <Col xs={12}>
                    <Pagination
                      count={5}
                      color="primary"
                      className={`site_pagination d-flex`}
                    />
                  </Col>
                </Box>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Highlights Section */}
      <Section
        id="block-highlights"
        className="section-block"
        title={`Festival Highlights`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <OwlCarousel
          className="owl-theme carousel-mirror"
          items={2}
          margin={15}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='/icons/arrow-sm-prev.svg' />",
            "<img src='/icons/arrow-sm-next.svg'/>",
          ]}
        >
          {highlights.map((highlight, key) => (
            <Card {...highlight} key={key} />
          ))}
        </OwlCarousel>
      </Section>

      {/* Places Section  */}
      <Section
        id="block-reg"
        className="reg-slide-block pt-5 mob-pt-0"
        renderedIn={`container`}
      >
        <OwlCarousel
          className="owl-theme"
          items={1}
          margin={0}
          loop={false}
          nav={true}
          dots={false}
          autoplay={false}
          smartSpeed={1200}
          autoplayTimeout={10000}
          navText={[
            "<img src='icons/arrow-prev.svg'></i>",
            "<img src='icons/arrow-next.svg'/>",
          ]}
        >
          {places.map((place, key) => (
            <Place {...place} key={key} />
          ))}
        </OwlCarousel>
      </Section>

      {/* Essentials */}
      <Essentials />
    </React.Fragment>
  );
};

export default EventList;
