import React from 'react';
import { Col, Card } from "react-bootstrap";

import './guideProfile.css';

const GuideProfile = ({ guideName, guideAvatar, guideDecription}) => {
  return (
    <Col lg={4}>
      <Card>
        <Card.Img variant="top" src="/details/tourGuideBG.jpg" />
        <Card.Body className="text-center">
          <img className="tour-guide rounded-circle" src={guideAvatar}/>
          <h4 className="text-black font-flair">{ guideName }</h4>
          <h6 className="card-subtitle mb-2 text-muted">Tourist Guide</h6>
          <p className="text-black ibm-font-18">{guideDecription}</p>
          <span className="ibm-font-18 border p-2 mt-2"> 
            <i className="las la-phone"/>
          </span>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default GuideProfile;
