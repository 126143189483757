import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CardItem = ({ heading, imageUrl, to }) => {
  return (
    <Col lg={6} className="border-0 rounded-0">
      <Link to={to} className="img_link d-block mb-2">
        <img
          src={imageUrl}
          className="card-img-top w-100 rounded-0"
          alt="..."
          title=""
        />
      </Link>
      <h5 className="mb-2">
        <Link
          to={to}
          className="text-black text-decoration-none p-0 font-flair font-flair-22 hover-theme"
        >
          { heading }
        </Link>
      </h5>
    </Col>
  );
};

export default CardItem;
