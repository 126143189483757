import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header, Footer, NewsLetter } from "@components";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/") document.body.classList.add("pageDetail");
    else document.body.classList.remove("pageDetail");
  }, [pathname]);

  return (
    <React.Fragment>
      <Header />
      <Outlet />
      <NewsLetter />
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
