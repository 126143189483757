export const events = [
  {
    title: `Thrissur Pooram`,
    venue: `Thekkinkadu Maidanam`,
    conductingDate: "1",
    imgUrl: "/discoverKerala/discover1.jpg",
    conductingMonth: "May",
    district: "Thrissur",
  },
  {
    title: `Nehru Trophy Boat Race`,
    venue: `Punnamada Lake`,
    conductingDate: "14",
    imgUrl: "/discoverKerala/discover2.jpg",
    conductingMonth: "August",
    district: "Alappuzha",
  },
  {
    title: `Nishagandhi Dance & Music Festival`,
    venue: `Nishagandhi Auditorium`,
    conductingDate: "29",
    imgUrl: "/discoverKerala/discover3.jpg",
    conductingMonth: "December",
    district: "TVM",
  },
];

export const keralaSouvenirs = [
  {
    heading: "Aranmula Kannadi",
    imageUrl: "/whereToGo/listing1.jpg",
  },
  {
    heading: "Nettoorpetti",
    imageUrl: "/whereToGo/listing1.jpg",
  },
];
