import React, { useEffect } from 'react'; 
import { Row, Col } from "react-bootstrap";
import {
  PageBanner,
  BreadCrumb,
  Section,
  LinkButton,
  EventCard
} from "@components";

import CardItem from './shared/CardItem';
import { VideoItem } from "@pages/Home/shared";

import { events, keralaSouvenirs } from "./shared/data";


const SouvenirDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* pageBanner */}
      <PageBanner
        bannerImage={`/whereToGo/detailBannerBg.jpg`}
        bannerHeading={`Kerala Bronze Wares`}
      />

      {/* BreadCrumb */}
      <BreadCrumb />

      {/* Kerala Souvenirs */}
      <Section
        id="block-souvenirs"
        className="section-block"
        title={`Kerala Souvenirs`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {keralaSouvenirs.map((suvenirs, key) => (
            <CardItem {...suvenirs} key={key}/>
          ))}
        </Row>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>

      {/*  Promotion Video block */}
      <Section
        id="block-video"
        className="section-block"
        title={`Souvenirs Videos`}
        isContainerRelative={true}
        innerHead={true}
        renderedIn={`container`}
      >
        <VideoItem />
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>

      {/* Festivals / events */}
      <Section
        id="block-souvenirs"
        className="section-block"
        title={`Festivals / Events`}
        readMoreLink={`#`}
        renderedIn={`container`}
        innerHead={true}
        isContainerRelative={true}
      >
        <Row>
          {events.map((event, key) => (
            <Col lg={4} key={key}>
              <EventCard {...event}/>
            </Col>
          ))}
        </Row>
        <LinkButton
          isAbsolute={true}
          isCustom={true}
          link={`#`}
          linkText={`explore`}
        />
      </Section>
    </React.Fragment>
  )
};

export default SouvenirDetails