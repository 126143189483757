import React from "react";
import { isMobile } from "react-device-detect";

import './css/place.css';

const Place = ({
  title,
  description,
  mobileImgUrl,
  imgUrl
}) => {
  return (
    <div className="item">
      <div className="post-slide item-widget col-12">
        <div className="row">
          <img
            className="d-block w-100"
            src={ isMobile ? mobileImgUrl : imgUrl }
            alt=""
            title=""
          />
          <div className="col-lg-6 col-12 h-100">
            <div className="desc-default">
              <h3 className="text-black head-sub font-flair">
                { title }
              </h3>
              <p className="text-dark ibm-font-18 font-light">
                { description }
              </p>
              <a href="/" className="text-dark underline-0 read-more">
                more <i className="la la-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Place;
