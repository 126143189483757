import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'

import ErrorFallback from '@/utils/ErrorHandlers/Fallback'

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import './index.css';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
    >
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
);

