import React from "react";
import { Col, Table } from "react-bootstrap";
import parse from "html-react-parser";

const EventContent = ({
  eventTitle,
  eventContent,
  eventInfo
}) => {
  return (
    <Col xs={12}>
      <div className="list_section bg-white pb-0 pt-0 mob-pt-0">
        <div className="head-desc pb-0 mb-5 mob-mb-2">
          <h2 className={`head-title text-black font-flair font-flair-regular`}>
            {eventTitle}
          </h2>
        </div>
        {eventInfo && (
          <div className={`venue-desc d-flex mb-5 mb-sm-5 mb-xs-2`}>
            <Table bordered className={`w-auto text-center mb-0`}>
              <thead>
                <tr>
                  <th className={`ibm-semi-bold ibm-font-26`}>{eventInfo.date}</th>
                  <th className={`ibm-semi-bold ibm-font-26`}>{eventInfo.month}</th>
                  <th className={`ibm-semi-bold ibm-font-26`}>{eventInfo.venue}</th>
                </tr>
              </thead>
              <tbody className={`border-top-0`}>
                <tr>
                  <td className={`ibm-font-18 text-bold`}>{eventInfo.day}</td>
                  <td className={`ibm-font-18 text-bold`}>{eventInfo.year}</td>
                  <td className={`ibm-font-18 text-bold`}>{eventInfo.place}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
        <div className="page-content mb-5 mob-mb-3">{parse(eventContent)}</div>
      </div>
    </Col>
  );
};

export default EventContent;
