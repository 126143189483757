import React from 'react';
import { Grid, Alert } from "@mui/material"; 

const ErrorFalback = ({ error }) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{
        mt:2,
        px:5
      }}>
        <Alert severity="error">
          <pre>{error.message}</pre>
        </Alert>
      </Grid>
    </Grid>
  );
};

export default ErrorFalback;
