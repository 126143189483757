import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Pagination } from "@mui/material";

import './stayList.css';

const StayListFilter = ({ stayLists }) => {
  return (
    <ul className="top_filter_menu mb-3 text-center">
      {stayLists.map((stay, key) => (
        <li
          className={`${stay.className} ibm-font-18 text-black text-bold`}
          key={key}
        >
          {stay.stayType}
        </li>
      ))}
    </ul>
  );
};

const StayList = ({ stayLists }) => {
  return (
    <Box className="page-content mb-5 mob-mb-3">
      <Row>
        <Col xs={12}>
          <StayListFilter stayLists={stayLists} />
          <ul className="top_filter_item m-0">
            {stayLists.map(({ className, imageUrl, stayType }, key) => (
              <li className={className} key={key}>
                <a
                  href="#"
                  className="text-decoration-none ibm-font-16 text-black text-bold hover-theme"
                >
                  <img src={imageUrl} alt="" title="" />
                  <span className="d-block mt-2">{stayType}</span>
                </a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Box className="mt-5">
          <Col xs={12}>
            <Pagination count={5} color="primary" className={`site_pagination d-flex`}/>
          </Col>
        </Box>
      </Row>
    </Box>
  );
};

export default StayList;
