import React from "react";
import OwlCarousel from "react-owl-carousel";

import { Section, LinkButton } from "@components";
import StoryItem from './components/StoryItem';

import { keralaStoriesItems } from '../data';

const KeralaStory = () => {
  return (
    <Section
      id="block-kerala-story"
      className="section-block position-relative pt-3"
      title={`My Kerala story`}
      description={`Find amazing travel stories and videos shared by our informal ambassadors across the globe.`}
      isFluid={true}
      readMoreLink="#"
      renderedIn={`container-out`}
    >
      <OwlCarousel
        className="owl-theme carousel-mirror"
        items={1}
        margin={0}
        loop={false}
        nav={true}
        dots={true}
        autoplay={true}
        smartSpeed={1200}
        autoplayTimeout={10000}
      >
        {keralaStoriesItems.map((keralaStory, key) => (
          <StoryItem {...keralaStory} key={key} />
        ))}
      </OwlCarousel>
      <LinkButton isAbsolute={true} link={`#`} linkText={`explore`} />
    </Section>
  );
};

export default KeralaStory;
