import React from "react";
import { Col } from 'react-bootstrap';
import { Section } from "@components";
import PlanYourTrip from './Shared';

const Essentials = () => {

  return (
    <Section
      id="block-weather"
      className="section-block"
      title={`Essentials`}
      description={`Everything you need to consider for a trip to Kerala now.`}
      isFluid={false}
      isWrap={true}
      renderedIn={`container`}
    >
      <Col xs={12} className="block_nav_pills">
        <PlanYourTrip/>
      </Col>
    </Section>
  );
};

export default Essentials;
