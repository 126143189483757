import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "./search";
import Favourite from "./favourite";
import Language from "./language";

const menus = [
  {
    name: "Where to go",
    href: "/where-to-go",
  },
  {
    name: "What to do",
    href: "#",
  },
  {
    name: "Plan your trip",
    href: "#",
  },
  {
    name: "Kerala Tourism",
    href: "#",
  },
  {
    name: "Business",
    href: "#",
  },
  {
    name: "Contact",
    href: "#",
  },
];

const Navigation = () => {
  return (
    <Nav className="justify-content-end">
      {menus.map((menu, key) => (
        <Nav.Link as={Link} to={menu.href} key={key} className="nav-link">
          {menu.name}
        </Nav.Link>
      ))}
      <Search />
      <Favourite />
      <Language />
    </Nav>
  );
};

export default Navigation;
