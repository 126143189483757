import React from "react";

const PageBanner = ({ bannerImage, bannerHeading }) => {
  return (
    <section id="banner" className="p-0 position-relative">
       <div className="img-block">
        <img 
          src={bannerImage} 
          alt="" 
          title="" 
          className="w-100" 
        />
        <div className="banner_text_block w-100">
            <div className="container">
              <h2 className="text-white font-flair banner_text">{ bannerHeading }</h2>
            </div>
        </div>
      </div>
    </section>
  );
}

export default PageBanner;